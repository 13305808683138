<template>
    <div class="mt" shadow="always" box-shadow="0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">

        <div class="content">
            <el-button  type="text" @click="goBack" class="back" :style="backType==2?{position:'absolute'}:{}" v-if="backType==1||backType==2">返回</el-button>
            <slot></slot>
        </div>
        <div class="rightHeader">
            <el-button class="loBtn" type="text" @click="onLogout">退出登录</el-button>
            <span class="info">
                <div style="font:24px bold;margin-top: 20px">{{userName}}</div>
                <div style="font-size:18px" v-if="isAdmin">管理员</div>
                <div style="font-size:18px" v-if="isHr">HR</div>
            </span>
            <img :src="userUrl" style=" background: #FF9D0E;">
        </div>

    </div>
</template>

<script>
    export default {
        name: "MainTop",
        props:['backType'],
        data() {
            return {
                userUrl: require("../assets/user.png")
            }
        },
        computed:{
            userName(){
                return localStorage.getItem("lqName");
            },
        },
        methods: {
            onLogout() {
                this.$http.get("/api/logout").then(rsp => {
                    location.href = "/";
                })
            }
        }
    }
</script>

<style scoped>
    .mt {
        color: #888888;
        background: #FFFFFF;
        overflow: auto;
        position: relative;
    }

    .content {
        float: left;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 24px;
        font-size: 20px;
    }

    .rightHeader {
        position: absolute;
        float: right;
        height: 100px;
        display: flex;
        right: 0px;
        top: 0px
    }
    .back{
        /*position: absolute;*/
        top: 30px;
        left: 20px;
        float: left;
        margin-right: 20px;
    }
    .info {
        text-align: center;
        margin: 0 48px;
    }

    .logOut {
        height: 100px;
        float: right;
        /*弹性盒模型*/
        display: flex;
        /*主轴居中对齐*/
        justify-content: center;
        /*侧轴居中对齐*/
        align-items: center;
        flex-direction: column;
    }

    .loBtn {
        font-size: 18px;
        color: #EB920F;
        text-decoration: none;
        margin: 0 auto;
    }
</style>