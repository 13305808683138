<template>
    <div>
        <mt :backType="1">
            <el-form :inline="true" align="left" label-width="140px">
                <el-form-item label="客户姓名">
                    <el-input readonly v-model="form.customerName" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="客户电话">
                    <el-input readonly v-model="form.customerPhone" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-input readonly v-if="form.payType==1" value="现金" style="width: 160px">现金</el-input>
                    <el-input readonly v-if="form.payType==2" value="银行卡" style="width: 160px">银行卡</el-input>
                    <el-input readonly v-if="form.payType==3" value="微信" style="width: 160px">微信</el-input>
                    <el-input readonly v-if="form.payType==4" value="支付宝" style="width: 160px">支付宝</el-input>
                    <el-input readonly v-if="form.payType==5" value="其他" style="width: 160px">其他</el-input>
                </el-form-item>
                <el-form-item label="卡号">
                    <el-input readonly v-model="form.customerCard" style="width: 160px"></el-input>
                </el-form-item>
                <br>
                <el-form-item label="收货人">
                    <el-input readonly v-model="form.receiver" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="收货人电话">
                    <el-input readonly v-model="form.receiverPhone" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="收货地址">
                    <el-input readonly v-model="form.receiverAddress" style="width: 430px"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input readonly v-model="form.remark" style="width: 160px"></el-input>
                </el-form-item>
                <br>
                <el-form-item label="下单日期">
                    <el-input readonly v-model="form.createTime" style="width: 240px"></el-input>
                </el-form-item>
                <el-form-item label="本单返积分">
                    <el-input readonly v-model="form.score" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="下单客服">
                    <el-input readonly  style="width: 160px" v-model="form.userName"></el-input>
                </el-form-item>
                <el-form-item label="下单客服电话">
                    <el-input readonly style="width: 160px" v-model="form.userMobile"></el-input>
                </el-form-item>
                <el-form-item label="销售员">
                    <el-input readonly style="width: 160px" v-model="form.sellerName"></el-input>
                </el-form-item>
                <el-form-item label="销售员电话">
                    <el-input readonly style="width: 160px" v-model="form.sellerPhone"></el-input>
                </el-form-item>
            </el-form>
        </mt>
        <el-card style="margin: 30px" shadow="always">
            <div slot="header" class="clearfix">
                <span class="title">订单详情</span>
            </div>

            <el-table :data="form.details" stripe border class="funBtn">
                <el-table-column label="序号" type="index" width="80"></el-table-column>
                <el-table-column label="商品" prop="goodsName"></el-table-column>
                <el-table-column label="货号" prop="itemNumber"></el-table-column>
                <el-table-column label="单价" width="240">
                    <template slot-scope="scope">
                        <span>{{scope.row.price1 | fen2yuan}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="零售价" width="240">
                    <template slot-scope="scope">
                        <span>{{scope.row.price2 | fen2yuan}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="数量" prop="count"></el-table-column>
                <el-table-column label="小计">
                    <template slot-scope="scope">
                        {{(scope.row.price2*scope.row.count) | fen2yuan}}
                    </template>
                </el-table-column>
                <br/>
                <el-table-column label="备注">
                    <template slot-scope="scope">
                        <span v-if="scope.row.price2==0">赠送</span>
                        <span v-if="(scope.row.price2!=0)&&(scope.row.count>0)">销售</span>
                        <span v-if="(scope.row.price2!=0)&&(scope.row.count<0)">退货</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>

</template>

<script>
    import mt from "@/components/MainTop.vue"

    export default {
        components: {mt},
        name: "OrderDetail",
        props: ['orderId'],
        data() {
            return {
                form: {}
            }
        },
        mounted() {
            this.$http.get("/api/order/find", {params: {orderId: this.orderId, page: 1, size: 1}})
                .then(rsp => {
                    if (rsp.data.status == 200) {
                        if (rsp.data.data.length) {
                            this.form = rsp.data.data[0];
                        }
                    }
                })
        },
        methods: {}

    }
</script>

<style scoped>
    .el-form-item {
        margin: 18px auto
    }

    .el-form {
        padding: 18px 0;
    }
</style>
